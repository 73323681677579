import React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { ContactUs } from '../components/ContactUs'

const ContactPage = () => (
  <Layout>
    <SEO title='Contact Us' />
    <ContactUs />
  </Layout>
)

export default ContactPage
