import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const ContactUsWrapper = (base) => css`
  display: flex;
  flex-flow: row-nowrap;
  justify-content: center;
  position: relative;
  z-index: 3;
  height: 100vh;
  min-height: 100%;

  & > div:nth-of-type(1) {
    position: absolute;
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;

    background: linear-gradient(
      108.65deg,
      rgba(6, 68, 128, 0) 50%,
      rgba(255, 13, 80, 0.64) 100%
    );
  }

  & > div:nth-of-type(3) {
    position: absolute;
    width: 100vw;
    height: 400px;
    bottom: -1px;
    left: 0px;
    right: 0px;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;

    background: linear-gradient(180deg, rgba(1, 14, 26, 0) 0%, #010e1a 100%);
  }

  ${base.MQ.phone} {
    height: 100%;

    & > div:nth-of-type(1) {
      height: 100%;
      background: linear-gradient(
        94.64deg,
        rgba(6, 68, 128, 0) 50%,
        rgba(255, 13, 80, 0.64) 100%
      );
    }
  }
`

export const ContactUsContainer = (base) => css`
  display: flex;
  flex-flow: row nowrap;
  align-self: center;

  & > div:nth-of-type(1) {
    margin-right: 114px;
  }
  & > div:nth-of-type(2) {
    min-width: 589px;
  }

  ${base.MQ.phone} {
    flex-flow: column nowrap;
    align-self: unset;
    padding: 88px 0px;

    & > div:nth-of-type(1) {
      margin: 0px;
    }
    & > div:nth-of-type(2) {
      min-width: 100%;
    }
  }
`

export const ContactUsText = (base) => css`
  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.small,
      color: base.colorTertiary._500,
    })}
    margin: 0;
    padding: 32px 0px;
  }

  & > h2 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.large,
      color: base.colorNeutral._1000,
    })}
    margin: 0px;
  }

  & > span {
    display: block;
    ${js2css({
      ...base.typeEditorial.common,
      ...base.typeEditorial.large,
      color: base.colorTint._500,
    })}
    margin: 0;
    margin-top: 32px;
  }

  ${base.MQ.phone} {
    & > h1 {
      ${js2css({
        ...base.typeTitle.smallest,
      })}
      padding: 0px;
    }

    & > h2 {
      ${js2css({
        ...base.typeTitle.small,
      })}
      margin: 8px 0px;
    }

    & > span {
      display: block;
      ${js2css({
        ...base.typeEditorial.small,
      })}
      margin: 0px 0px 32px 0px;
    }
  }
`
