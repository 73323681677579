import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const ContactFormWrapper = (base) => css`
  display: flex;
  flex-flow: column nowrap;
`

export const FormElementsContainer = (base) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto minmax(288px, auto);
  grid-gap: 24px;
  margin-bottom: 24px;

  & > div:nth-of-type(1),
  & > div:nth-of-type(2),
  & > div:nth-of-type(3),
  & > div:nth-of-type(4) {
    grid-column: span 1;
  }

  & > div:nth-of-type(5) {
    grid-column: span 2;
    grid-row: span 1;
    resize: vertical;
  }

  ${base.MQ.phone} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto) minmax(288px, auto);
    grid-gap: 10px;

    & > div:nth-of-type(5) {
      grid-column: span 1;
    }
  }
`

export const MessageContainer = (base, show, result) => css`
  ${js2css({ ...base.typeEditorial.common, ...base.typeEditorial.large })}

  color: ${
    result
      ? result.status === 200
        ? `${base.colorTertiary._500}`
        : `${base.colorSecondary._500}`
      : `${base.colorSecondary._500}`
  };
  display: ${show ? 'static' : 'none'};

  text-align: right;
  margin-bottom: 24px;
`

export const SubmitContainer = (base) => css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  ${base.MQ.phone} {
    display: block;
    width: 100%;
  }
`
