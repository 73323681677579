import { React, useState } from 'react'
import emailValidator from 'email-validator'
import {
  ContactFormWrapper,
  FormElementsContainer,
  MessageContainer,
  SubmitContainer,
} from './ContactForm.css'
import { TextInput, TextAreaInput, Button, useKinetiq } from 'kinetiq'

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address.'

const defaults = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  content: '',
}

const errorDefaults = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  content: '',
}

export const ContactForm = () => {
  const kinetiq = useKinetiq()

  const [show, setShow] = useState(true)
  const [message, setMessage] = useState('')
  const [values, setValues] = useState(defaults)
  const [errors, setErrors] = useState(errorDefaults)
  const [result, setResult] = useState(false)

  const onSubmitHandler = async (e) => {
    e.preventDefault()

    if (typeof window !== `undefined`) {
      const path = window.location.protocol + '//' + window.location.host
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
      const response = await fetch(`${path}/.netlify/functions/contactFormSendGrid`, options)
        .then((res) => res)
        .catch((error) => error)

      setResult(response)

      if (response.status === 200) {
        console.log(response)
        setValues(defaults)
        setMessage('Thank you for your submission!')
        setShow(true)
      } else {
        console.log('An error occured while submitting the form!')
        setMessage('An error occured while submitting the form!')
        setShow(true)
      }
    }
  }

  const onChangeOther = ({ target }) => {
    let newValues = { ...values, [target.name]: target.value }
    setValues({ ...newValues })
    setResult(false)
    setShow(false)
  }

  const onChangeEmail = ({ target }) => {
    let newValues = { ...values, [target.name]: target.value }
    let newErrors = { ...errors }
    const valid = emailValidator.validate(target.value)
    if (!valid) {
      setErrors({ ...newErrors, email: INVALID_EMAIL_MESSAGE })
    } else {
      setErrors({ ...newErrors, email: '' })
    }
    setValues({ ...newValues })
    setResult(false)
    setShow(false)
  }

  return (
    <div css={ContactFormWrapper(kinetiq.base)}>
      <form onSubmit={onSubmitHandler} id='ContactForm'>
        <div css={FormElementsContainer(kinetiq.base)}>
          <TextInput
            type='text'
            name='firstName'
            error={errors.firstName}
            label='First Name'
            placeholder='Jane'
            value={values.firstName}
            onChangeHandler={onChangeOther}
          />
          <TextInput
            type='text'
            name='lastName'
            error={errors.lastName}
            label='Last Name'
            placeholder='Doe'
            value={values.lastName}
            onChangeHandler={onChangeOther}
          />
          <TextInput
            type='email'
            name='email'
            error={errors.email}
            label='Email Address'
            placeholder='jane@acme.com'
            value={values.email}
            onChangeHandler={onChangeEmail}
          />
          <TextInput
            type='text'
            name='company'
            error={errors.company}
            label='Company'
            placeholder='Acme, Inc.'
            value={values.company}
            onChangeHandler={onChangeOther}
          />
          <TextAreaInput
            name='content'
            error={errors.content}
            label="What's on your mind..."
            placeholder=''
            value={values.content}
            onChangeHandler={onChangeOther}
          />
        </div>
        <div css={MessageContainer(kinetiq.base, show, result)}>{message}</div>
        <div css={SubmitContainer(kinetiq.base)}>
          <div>
            <Button
              text='Send it'
              disabled={
                Object.values(values).includes('') ||
                Object.values(errors).some((x) => x !== '')
                  ? true
                  : false
              }
            />
          </div>
        </div>
      </form>
    </div>
  )
}
