import React from 'react'
import { ContactUsWrapper, ContactUsContainer, ContactUsText } from './ContactUs.css'
import { ContactForm } from '../ContactForm'
import { useKinetiq } from 'kinetiq'

export const ContactUs = () => {
  const kinetiq = useKinetiq()
  return (
    <div css={ContactUsWrapper(kinetiq.base)}>
      <div></div>
      <div css={ContactUsContainer(kinetiq.base)}>
        <div css={ContactUsText(kinetiq.base)}>
          <h1>Contact Us</h1>
          <h2>We'd love to hear from you!</h2>
          <span>Ask us anything, and we'll try to get back to you as soon as we can.</span>
        </div>
        <ContactForm />
      </div>
      <div></div>
    </div>
  )
}
